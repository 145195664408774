<template>
<!-- 废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃废弃 -->
  <el-card class="v_user_black_list">
    <div class="g-flex"  slot="header">
      <span>用户黑名单列表</span>
      <div class="g-flex-justify-end g-flex-1">
        <el-button size="mini" type="success" @click="setForm()">新增黑名单</el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <el-form :inline="true" size="mini" :model="query">
      <el-form-item label="状态">
        <el-select v-model="query.status" @change="getList()">
          <el-option label="全部" value="all"></el-option>
          <el-option label="有效" value="1"></el-option>
          <el-option label="过期" value="0"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-select slot="label" v-model="query.search_key" placeholder="查询">
          <el-option label="操作人" value="operator"></el-option>
          <el-option label="ip" value="ip"></el-option>
          <el-option label="ip地址" value="ip_address"></el-option>
        </el-select>
        <el-row>
          <el-col :span="18">
            <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-button type="primary" @click="getList()">查询</el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table stripe size="mini" v-loading="table.loading" :data="table.rows" border>
      <!-- <el-table-column prop="ent_id" label="企业ID" width="80" :show-overflow-tooltip="true" v-if="$global.adminUser.type === 4"/> -->
      <el-table-column prop="operator" label="操作人" width="100" :show-overflow-tooltip="true"/>
      <el-table-column prop="ip" label="ip" width="140" :show-overflow-tooltip="true"/>
      <el-table-column prop="ip_address" label="ip地址" :show-overflow-tooltip="true"/>
      <el-table-column label="创建时间" width="140" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm')}}</span>
        </template>
      </el-table-column>
      <el-table-column label="到期时间" width="140" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{formatDate(scope.row.expir_time, 'yyyy-MM-dd hh:mm')}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="80" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="g-red">过期</span>
            <span v-else-if="scope.row.status==1" class="g-green">有效</span>
          </template>
      </el-table-column>
      <el-table-column label="操作" width="80">
        <template slot-scope="scope">
          <el-button  size="mini" type="danger" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    
    <!--新增和编辑-->
    <el-dialog title="新增黑名单" :close-on-click-modal="false" :visible.sync="form">
        <el-form :model="formData" label-width="100px" size="mini">
            <!-- <el-form-item v-if="$global.adminUser.type === 4" label="id">
              <el-input v-model="formData.ent_id" autocomplete="off"></el-input>
            </el-form-item> -->
            <el-row>
              <el-col :span="12">
                <el-form-item label="手机号:">
                  <el-input v-model="formData.ip" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
            </el-row>


            <el-form-item label="屏蔽时长">
              <el-select v-model="formData.expir_time">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="form = false">取 消</el-button>
            <el-button type="primary" @click="save()">确 定</el-button>
        </div>
    </el-dialog>

  </el-card>
</template>

<script>
  import { formatDate } from '@/utils/index.js';
  import { apiGetIpBackList, apiAddIpBlack, apiDelIpBlack } from '@/utils/api.js'
  export default {
    data() {
      return {
        auth:{
          addBlack: this.$global.ApiList.includes(60),
          delBlack: this.$global.ApiList.includes(61),
        },

        query: { search_key: 'operator',search_val: '', page:1, limit: 15, status: 'all' },

        table:{ loading:false, total:0, rows:[] },

        form: false,

        formData: {
          ent_id: '',
          ip: '',
          expir_time: 0
        },

        options: [
          {
            value: 0,
            label: '长期屏蔽'
          },
          {
            value: 60 * 30,
            label: '屏蔽半小时'
          }, 
          {
            value: 60 * 60,
            label: '屏蔽一小时'
          }, 
          {
            value: 60 * 60 * 2,
            label: '屏蔽两小时'
          }, 
          {
            value: 60 * 60 * 6,
            label: '屏蔽六小时'
          }, 
          {
            value:  60 * 60 * 24,
            label: '屏蔽一天'
          }],
      }
    },
    created() {
      this.getList()
    },
    methods: {
      formatDate,

      async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        this.table.loading = true
        const { success, data } = await apiGetIpBackList(this.query)
        this.table.loading = false
        this.table.rows = data.rows
        this.table.total = data.total
        if(!success) return
      },

      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      },

      //设置form
      setForm(formData = { ent_id: '', ip: '', expir_time: 0  }) {
        this.formData = Object.assign({}, formData)
        this.form = true
      },

      // 点击保存
      async save() {
        if(!this.formData.ip) return this.$message.error('ip地址不能为空')
        const loading = this.$loading()
        const { success, data } = await apiAddIpBlack(this.formData)
        loading.close()
        if(!success) return
        this.$message.success(data.msg)
        this.form = false
        this.getList()
      },

      // 点击删除
       del({ id }) {
        this.$confirm('将该用户永久移出黑名单, 是否继续?', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.table.loading = true
          const { success, data } = await apiDelIpBlack({ id: id })
          this.table.loading = false
          if(!success) return
          this.$message.success(data.msg)
          this.getList()
        }).catch(()=>{})
      }, 
      
    }
  }
</script>

<style lang="scss">
</style>